import { graphql, type HeadFC, type PageProps } from 'gatsby';
import React, { type FC } from 'react';

import { FiizyCalculator } from '@/components/calculators/fiizy/fiizy-calculator';
import { AppHead } from '@/components/head';

interface FiizyApplicationPageProps {
    page: GraphqlSelect.Page<'id' | 'metaTitle' | 'metaDescription'>;
}

const FiizyApplicationPage: FC<PageProps<FiizyApplicationPageProps>> = () => {
    return (
        <div style={{ minHeight: '700px' }} className="container max-lg">
            <FiizyCalculator />
        </div>
    );
};

export const Head: HeadFC<FiizyApplicationPageProps> = ({ data: { page } }) => {
    return (
        <AppHead title={page.metaTitle} description={page.metaDescription} ogImage={{ id: page.id, type: 'pages' }} />
    );
};

export const query = graphql`
    query FiizyApplicationPage {
        page: pages(uri: { url: { eq: "/fiizy/" } }) {
            id
            metaTitle
            metaDescription
        }
    }
`;

export default FiizyApplicationPage;
